/**
 * Basic typography style for copy text
 */

body {
  color: $Black-1;
  // font: normal 125% / 1.4 $text-font-stack;
  // font-family: 'Manrope', sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $Grey-5;
}
:root {font-size: 16px;}

// Headers
h1 { font-size: 2.25rem; font-weight: $font-w-bold; margin-bottom: 1rem;}
h2 { font-size: 1.875rem; font-weight: $font-w-bold;}
h3 { font-size: 1.5rem; font-weight: $font-w-bold;}
h4 { font-size: 1.125rem; font-weight: $font-w-bold; margin-bottom: 1rem}
h5 { font-size: 1rem; font-weight: $font-w-bold;}
h6 { font-size: .875rem; font-weight: $font-w-bold;}
.bg-black-2 {
  background-color: $Black-2;
}
// Paragraph
p {
  // line-height: $font-s-base*1.5;
  color: $Black-2;
  font-weight: $font-w-medium;
}
.text-primary {color: $Primary-1 !important;}
.text-2x-small {
  font-size: $font-2x-small;
}
// Badge
.badge {
  &.small {
    font-weight: $font-w-regular;
  }
}
// Opacity
.opacity-6 { opacity: .6; }