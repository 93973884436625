// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;





/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;


// Black Colors
$Black-1: #1E1E1F;
$Black-2: #4E4E4F;
$Black-3: #818182;

// Grey Colors
$Grey-1: #9A9A9C;
$Grey-2: #CCCCCF;
$Grey-3: #D9D9DB;
$Grey-4: #EBEBED;
$Grey-5: #F5F5F7;
$Blue-Grey-5: #F1F5FD;
// Red Colors
$Red-1: #750E13;

// Yellow Colors
$Yellow-1: #CFA838;
$Yellow-2: #FFEDB2;

// Orange Colors
$Orange-1: #F8823D;

// Primary Colors
$Primary-1: #0078D7;
$Primary-2: #0069BD;

// Blue Colors
$Blue-5: #DBE7FF;

// White Colors
$White-1: #FFFFFF;

// Gradient Colors
$Black-Linear-bt: linear-gradient(0deg, rgba(30,30,31,1) 0%, rgba(30,30,31,0) 100%);

// 300;400;500;600;700
// Font width
$font-w-bold: 700;
$font-w-medium: 500;
$font-w-semibold: 600;
$font-w-regular: 400;
$font-w-light: 300;

// Font size
$font-s-base: 1rem;
$font-s-small: .875rem;
$font-2x-small: .75rem;
$font-s-medium: 1.125rem;
$font-s-large: 1.25rem;

// Border radius
$br-base: .5625rem;
$br-medium: .6875rem;
$br-large: .8125rem;
$br-xl: 1rem;
$br-2xl: 1.875rem;


// Margin
$m-base: .5rem;
$m-medium: .75rem;
$m-large: 1rem;
$m-xl: 1.5rem;
$m-2xl: 1.875rem;
$m-3xl: 2.25rem;

// Padding
$p-base: .5rem;
$p-medium: .75rem;
$p-large: 1rem;
$p-xl: 1.5rem;
$p-2xl: 1.875rem;
$p-3xl: 2.25rem;
$p-4xl: 2.5rem;
$p-5xl: 3rem;
$p-6xl: 3.5rem;
$p-7xl: 4rem;
$p-8xl: 4.5rem;
$p-9xl: 5.25rem;

// Box shadow
$box-s-base: 0 1px 2px 0 rgba($color: $Black-1, $alpha: .20);

// Border
$border-grey-4: 1px solid $Grey-4;

/// Container's maximum width
/// @type Length
// $max-width: 1140px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: '/assets/' !default;
