// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.home-slider {
    padding-top: $p-5xl;
    padding-bottom: $p-5xl;
    h4 {
        color: $Orange-1;
    }
    h2 {
        margin-bottom: $m-xl;
    }
    p {
        margin-bottom: $m-3xl;
    }
    .promo-graphic {
        height: 250px;
    }
}

.benefits {
    padding-top: $p-9xl;
    padding-bottom: $p-9xl;
}

/* How it works */
.hw{
	padding: $p-9xl 0;
}
.slick-dots {
	position: relative;
    li {
        position: relative;
        display: inline-block;
        margin: 0 $m-base;
        padding: 0;
        cursor: pointer;
        font-size: $font-s-base;
        line-height: 1.725rem;
        div {
            border-radius: $br-base !important;
            width: 2rem !important;
            height: 2rem !important;
        }
        &.slick-active {
            div{
                width: 2rem !important;
                height: 2rem !important;
                color: $White-1 !important;
                border: 3px solid $Black-1;
                background: $Black-1;
            }
        }
    }
}

.home-c2a{
	padding: $p-9xl 0 $p-9xl*6 0;
    background-image: url(../../../src/img/bg-c2a.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
}