// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
.bg-primary {background-color: $Primary-1 !important;}
.bg-dark {background-color: $Black-1 !important;}
.bg-yellow {background-color: $Yellow-2 !important;}
.bg-white {background-color: $White-1 !important;}
.bg-info {background-color: $Blue-5 !important;}
.avatar-placeholder{
	height: 2.25rem;
	width: 2.25rem;
	background-color: $Grey-4;
	color: $Primary-1;
	font-size: 0.875rem;
	text-transform: uppercase;
}
// Cards
.card{
    border-radius: $br-base;
    border: $border-grey-4;
    box-shadow: none;
    .card-title {
        padding: $p-2xl $p-2xl 0;
    }
    .card-body{
        padding: $p-2xl;
        font-size: $font-s-small;
        color: $Black-1;
        line-height: $font-s-base*1.5;
    }
}
// Form
label {
    color: $Black-2;
    font-size: $font-2x-small;
    margin-bottom: 0;
}
form {
    label {
        font-size: $font-s-small;
        font-weight: $font-w-medium;
        color: $Black-3;
    }
    .form-control {
        height: 3.125rem;
        border: 2px solid $Grey-3;
        border-radius: $br-base;
        box-shadow: none;
        color: $Black-1;
        font-size: $font-s-small;
        &.bottom-line {
            padding: 0;
            border-bottom: 2px solid $Black-1 !important;
            height: 2rem;
            border-radius: 0;
            font-size: $font-s-base;
            padding-right: 2.25rem !important;
        }
        &:focus {
            border: 2px solid $Black-1;
            box-shadow: none;
            color: $Black-1;
        }
    }
    textarea {
        height: 12.5rem !important;
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
// textarea.form-control:not(.view-textarea) {
    
// }
// Floating button
.float-btn-group {
	top: 36%;
	right: 0;
    background-color: $White-1;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: $p-base 0 $p-base 0;
    box-shadow: $box-s-base;
    .btn {
        width: 2.25rem;
        height: 2.25rem;
        padding: 0 !important;
        line-height: 26px;
        display: block;
        // background-color: $Grey-1;
        &:hover{
            // background-color: $Primary-1;
        }
    }
}

// Resume preview
#resumePreviewModal .modal-dialog{
	max-width: 80%;
}
.modal {
    .close{
        z-index: 1;
        right: 15px;
        top: 15px;
    }
}

.preview-panel{
	top: 0;
	bottom: 0;
    &.bg-grey {
        &::before {
            background-color: $Grey-1;
        }
    }
    &::before {
        content: '';
        height: 100%;
        display: block;
        position: fixed;
        width: 100%;
        z-index: -1;
        &canvas {
            border-radius: 10px !important;
            margin-bottom: 10px;
        }
    }
    .action-bar{
        z-index: 1;
        height: 69px;
        .btn-back:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }
    .action-title {
        padding-top: 10px;
        z-index: -1;
    }
}
.action-btn-group .btn{
	height: 48px;
	width: 48px;
	line-height: 44px;
}
.social-btn-group .btn{
	height: 48px;
	width: 48px;
}
.social-btn-group .SocialMediaShareButton{
	display: inline-block;
}
.btn-facebook{
	background-color: #3B5998;
}
.btn-facebook:hover{
	background-color: #2C4373;
}
.btn-twitter{
	background-color: #1DA1F2;
}
.btn-twitter:hover{
	background-color: #1887CC;
}
.btn-linkedin{
	background-color: #0077B5;
}
.btn-linkedin:hover{
	background-color: #005D8F;
}
.preview-container{
	width: 918px;
	min-height: 11in;
	margin-top: 8rem;
	border-radius: 10px;
}
/* SWITCH */
.react-switch-handle{
	height: 0 !important;
}
.react-switch-bg{
	height: 28px !important;
	width: 30px !important;
	border-radius: 4px !important;
}
/* FULL SCREEN VIEW */
.full-screen{
	top: 0;
	min-height: 500px;
	background: #ecf2f9;
}
.w-918 {
	width: 918px;
	margin: 0 auto;
}
.full-screen .logo-sm{
	height: 36px;
	margin-left: 10px;
}

/* COOKIES DIALOG */
.cookies-dialog {
	width: 320px;
	bottom: 50px;
	left: 50px;
	z-index: 1;
}
.cookies-dialog p {
	font-size: 0.875rem;
}
.view-textarea {
	resize: none;
    font-size: $font-s-small;
    color: $Black-1;
    overflow: hidden;
	/* margin-bottom: 20px; */
}
textarea.form-control:not(.view-textarea) {
	height: 200px;
}

.modal {
    .modal-header {
        .close {
            &:focus {
                outline: 0;
            }
            img {
                height: 1.5rem;
            }
        }
    }
    .modal-dialog {
        max-width: 37.5rem;
        .modal-content {
            border-radius: $br-2xl;
            .modal-body {
                height: 25rem;
            }
        }
    }
}

// Brand Logo Small
.brand-logo-sm {height: 42px;}
.action-bar-top {z-index: 1;}
.text-black-3 {color: $Black-3;}

/* FAQ */
.faq {
	padding: $p-9xl 0;
    #accordionFaq {
        .card {
            border-radius: $br-base;
            box-shadow: none;
            .card-header {
                padding: $p-large;
                background-color: $Grey-5;
                .btn-link {
                    color: $Black-1;
                    text-decoration: none;
                    text-align: left;
                    padding: 0;
                    font-size: $font-s-base;
                }
            }
            .card-body {
                padding: $p-large;
                background-color: $Grey-5;
                font-size: $font-s-small;
            }
        }
    }
}

// Navbar dropdown
.dropdown-menu{
	padding: 0.9375rem 1.25rem;
}
.dropdown-item{
	padding: 0.625rem 0.625rem;
	font-size: 0.875rem;
}
.navbar .dropdown-item:hover, .navbar .dropdown-item:focus{
    color: #16181b;
    text-decoration: none;
    background-color: #0084F0;
}
.dropdown-item.active, .dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: #0068BD;
}
.dropdown-divider {
    border-top: 1px solid #0082EA;
}
.dropdown-item img{
	margin-top: -2px;
}

.iframe-big {
    height: 500px;
    border-radius: 2rem;
}

.icon-medium {
    height: .75rem;
    vertical-align: text-top;
}
// Alerts
.alert-info {
    background-color: $Blue-5;
    color: $Black-1;
}