// -----------------------------------------------------------------------------
// This file contains styles that are specific to the pricing page.
// -----------------------------------------------------------------------------
.card {
    &.pricing {
        .card-title {
            .plan {
                color: $Black-3;
            }
        }
        .card-body {
            ul {
                padding: 0;
                li {
                    font-size: $font-s-small;
                    list-style: none;
                }
            }
        }
    }
}
.pricing-table {
    padding-bottom: $p-9xl;
    .on-floating {
        top: -10rem;
        margin-bottom: -16rem;
        position: relative;
    }
}
.page-title {
    position: relative;
    &.pricing {
        padding-bottom: $p-9xl*2;
    }
    .welcome-pricing {
        position: absolute;
        top: -2rem;
        left: 0;
        right: 0;
        z-index: -1;
    }
}
.payment-ins {
    padding-top: $p-4xl;
    padding-bottom: $p-4xl;
    img {
        &.ap-logo {
            height: 1.875rem;
        }
    }
}
.payment-iframe {
    height: 100%;
    width: 100%;
    border: none;
}