// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.navbar{
    // box-shadow: $box-s-base;
    border-bottom: $border-grey-4;
    .navbar-nav {
        .nav-link {
            font-size: .875rem;
            font-weight: $font-w-medium;
            margin-right: .625rem;
            margin-left: .625rem;
            &.active {
                // color: $Primary-1 !important;
            }
        }
    }
    img{
        &.brand-logo {
            height: 2.5rem;
        }
    }
}