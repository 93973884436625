// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard page.
// -----------------------------------------------------------------------------
.card {
    &.rc {
        .card-body {
            border-radius: $br-medium;
        }
        .rc-meta-info {
            .thumbnail {
                width: 100%;
            }
            position: relative;
            button {
                &.btn-floating {
                    position: absolute;
                    top: 7px;
                    right: 0px;
                    outline: 0;
                    box-shadow: none;
                }
            }
            h5 {
                &.title {
                    font-weight: $font-w-regular;
                    border-bottom: 2px solid transparent !important;
                    height: 2rem;
                    border-radius: 0;
                    &:hover {
                        color: $Black-1;
                        border-bottom: 2px solid $Black-1 !important;
                    }
                }
            }
            p {
                &.u-date {
                    color: $Black-3;
                }
            }
        }
    }
}
.nav-container-dashboard {
    .btn-absolute {
        position: absolute;
        right: 0;
        top: -1.25rem;
    }
    .nav-tabs {
        border-bottom: 2px solid $Grey-4;
        .nav-link {
            border: none;
            color: $Black-3;
            padding-left: 0;
            padding-right: 0;
            margin-right: 2rem;
            font-size: 1.125rem;
            background: transparent;
            &.active {
                border: none;
                color: $Primary-1;
                border-bottom: 2px solid $Primary-1;
                background-color: transparent;
            }
            &:hover {
                &:not(.active) {
                    border: none;
                    color: $Primary-1;
                }
            }
            &:focus {
                outline: 0;
            }
        }
    }
}